import React, { Component } from "react"
import { graphql } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import { CountUp } from "countup.js"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"
import Demo from "../../components/demo"
import Teamlist from "../../components/teamlist"

import World from "../../images/icons/world.svg";
import Team from "../../images/icons/team.svg";
import CutPath from "../../images/icons/cut-path.svg";

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default
    const counterOptions = {
      duration: 2,
      separator: ' ',
      suffix: '+'
    };

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-2', {
      delay: 100
    });

    ScrollReveal().reveal('.sr-3', {
      delay: 200,
      viewOffset: {
        right: '-500'
      }
    });

    ScrollReveal().reveal('.sr-4', {
      delay: 300,
      viewOffset: {
        right: '-500'
      }
    });

    ScrollReveal().reveal('.sr-5', {
      delay: 400,
      viewOffset: {
        right: '-500'
      }
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });

    ScrollReveal().reveal('.sr-b', {
      origin: 'bottom'
    });

    ScrollReveal().reveal('.sr-q', {
      delay: 100,
      beforeReveal: function () {
        document.getElementsByClassName('sr-q')[0].classList.add('is-loaded');
      }
    });

    ScrollReveal().reveal('.sr-c-1', {
      delay: 0,
      beforeReveal: function () {
        const counterYears = new CountUp('counter-years', 11, counterOptions);
        counterYears.start();
      }
    });

    ScrollReveal().reveal('.sr-c-2', {
      delay: 100,
      beforeReveal: function () {
        const counterBrands = new CountUp('counter-brands', 1000, counterOptions);
        counterBrands.start();
      }
    });

    ScrollReveal().reveal('.sr-c-3', {
      delay: 200,
      beforeReveal: function () {
        const counterMails = new CountUp('counter-employee', 100, counterOptions);
        counterMails.start();
      }
    });

    ScrollReveal().reveal('.sr-c-4', {
      delay: 0,
      beforeReveal: function () {
        const counterYears = new CountUp('counter-satisfaction', 96, {
          duration: 2,
          suffix: '%'
        });
        counterYears.start();
      }
    });

    ScrollReveal().reveal('.sr-c-5', {
      delay: 100,
      beforeReveal: function () {
        const counterBrands = new CountUp('counter-delivered', 96.5, {
          duration: 2,
          decimalPlaces: 1,
          decimal: ',',
          suffix: '%'
        });
        counterBrands.start();
      }
    });

    ScrollReveal().reveal('.sr-c-6', {
      delay: 200,
      beforeReveal: function () {
        const counterMails = new CountUp('counter-mails', 400000000, counterOptions);
        counterMails.start();
      }
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Наша команда" description="Наша команда — развивайте бизнес, используя автоматизацию многоканального маркетинга, основанную на данных. Побеждайте конкурентов!" />
        <Hero title="Наша команда" subtitle="Что делает нас надежным партнером в бизнесе?" />

        <div className="container container--pad pad-t-2 pad-b-1 mar-t-6">
          <div className="section-h sr-hidden sr-1">
            <h2 className="section-h__2 mar-b-6">
              <FormattedMessage id="team.h_1" />
            </h2>
          </div>

          <div className="columns is-variable is-8">
            <div className="column sr-hidden sr-1">
              <World className="section-i__ico" />
              <p className="section-h__3 mar-t-2 mar-b-1">
                В нашей команде <strong>более 100 экспертов</strong> в <strong>4-х офисах</strong> на <strong>3-х континентах</strong>.
              </p>
            </div>
            <div className="column sr-hidden sr-2">
              <Team className="section-i__ico" />
              <p className="section-h__3 mar-t-2 mar-b-1">
                Наша команда включает опытных <strong>разработчиков</strong>, увлеченных <strong>маркетологов</strong>, <strong>специалистов по продажам</strong>, профессиональных <strong>аналитиков</strong>, а также <strong>проектных менеджеров</strong> и <strong>менеджеров по работе с клиентами</strong>.
              </p>
            </div>
            <div className="column sr-hidden sr-3">
              <CutPath className="section-i__ico section-i__ico--80" />
              <p className="section-h__3 mar-t-2 mar-b-1">
                На нашем счету <strong>более 11 лет опыта</strong> в разработке эффективных клиентских стратегий и маркетинговых кампаний.
              </p>
            </div>
          </div>
        </div>

        {/*<div className="container container--pad mar-b-6">
          <div className="section-h mar-t-6 sr-hidden sr-1">
            <h2 className="section-h__2 mar-b-2">
              <FormattedMessage id="team.h_2" />
            </h2>
            <p className="section-h__3">
              <FormattedMessage id="team.t_4" />
            </p>
          </div>
    </div>*/}

        <Demo props={this.props.data} />
      </Layout>
    )
  }
}

export default SuccessStories

export const pageQuery = graphql`
  query {
    esDashboard: file(relativePath: { eq: "expertsender-dashboard.png" }) {
      childImageSharp {
        fluid(maxWidth: 1260, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
